import {isNil, isString} from '@malt/common';
import DOMPurify from 'dompurify';
import escape from 'lodash-es/escape';

async function initDOMPurifyWithJSDOM() {
    const {Window} = await import('happy-dom');
    return DOMPurify(new Window());
}

async function resolveDOMPurify() {
    const isClientSide = typeof window !== 'undefined';
    return isClientSide ? DOMPurify : await initDOMPurifyWithJSDOM();
}

export async function purify(input) {
    return (await resolveDOMPurify()).sanitize(input);
}

export function sanitize(input) {
    return isString(input) ? escape(input) : input;
}

export function sanitizeToString(input) {
    return isNil(input) ? '' : escape(input.toString());
}

// Just an alias, use same wording as existing code.
export function escapeHtml(input) {
    return sanitizeToString(input);
}

export function escapeJs(input) {
    if (typeof input !== 'string') {
        return input;
    }

    const filtered = [];
    let prevChar = '\u0000';
    let c;
    for (let i = 0; i < input.length; i++) {
        c = input.charAt(i);
        if (c === '"') {
            filtered.push('\\"');
        } else if (c === "'") {
            filtered.push("\\'");
        } else if (c === '\\') {
            filtered.push('\\\\');
        } else if (c === '/') {
            filtered.push('\\/');
        } else if (c === '\t') {
            filtered.push('\\t');
        } else if (c === '\n') {
            if (prevChar !== '\r') {
                filtered.push('\\n');
            }
        } else if (c === '\r') {
            filtered.push('\\n');
        } else if (c === '\f') {
            filtered.push('\\f');
        } else if (c === '\b') {
            filtered.push('\\b');
        } else if (c === '\v') {
            filtered.push('\\v');
        } else if (c === '<') {
            filtered.push('\\u003C');
        } else if (c === '>') {
            filtered.push('\\u003E');
        } else if (c === '\u2028') {
            // Unicode for PS (line terminator in ECMA-262)
            filtered.push('\\u2028');
        } else if (c === '\u2029') {
            // Unicode for LS (line terminator in ECMA-262)
            filtered.push('\\u2029');
        } else {
            filtered.push(c);
        }
        prevChar = c;
    }
    return filtered.join('');
}
